
import { Component, Vue } from 'vue-property-decorator';
@Component({
  components: {
    VisitorsPerMonthChart: () => import('@/components/charts/VisitorsPerMonthChart.vue'),
    AverageClicksPerVisitorChart: () => import('@/components/charts/AverageClicksPerVisitorChart.vue'),
    BounceRateChart: () => import('@/components/charts/BounceRateChart.vue'),
    SessionDurationChart: () => import('@/components/charts/SessionDurationChart.vue'),
    VisitorSourcesChart: () => import('@/components/charts/VisitorSourcesChart.vue'),
    VisitorReferralsChart: () => import('@/components/charts/VisitorReferralsChart.vue'),
    UniqueNewVisitorsPerDayChart: () => import('@/components/charts/UniqueNewVisitorsPerDay.vue'),
  }
})
export default class Visitors extends Vue {
  dateRangeSelector = {
    fromToggle: false,
    from: new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() - 7).toISOString().substring(0, 10),
    toToggle: false,
    to: new Date().toISOString().substring(0, 10),
  }

  get fromDate() {
    return new Date(this.dateRangeSelector.from);
  }

  get toDate() {
    return new Date(this.dateRangeSelector.to);
  }

}

